export default function About() {
	return (
		<div className='about'>
			<h3 className='about-title'>About</h3>
			<p className='about-description'>
				I am a frontend developer with a particular interest in making things
				simple and automating daily tasks. I try to keep up with security and
				best practices, and am always looking for new things to learn.
			</p>
		</div>
	);
}
